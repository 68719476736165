<template>
  <v-dialog v-model="dialog" persistent max-width="300">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @click="name = topname">
        mdi-pencil
      </v-icon>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-card-text>
          <v-row>
            <v-col>
              <v-img v-show="imgUrl" :src="imgUrl"></v-img>
              <v-file-input
                label="画像の選択"
                v-model="file"
                accept=".png,.jpg,.jpeg"
                :rules="photoRules"
                @change="disp()"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="名前"
                v-model="name"
                :rules="rules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="clear()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["topname", "photo"],
  data() {
    return {
      dialog: false,
      valid: false,
      rules: [(v) => !!v || "入力してください"],
      name: "",
      file: null,
      imgUrl: "",
      photoRules: [
        (value) =>
          !value ||
          value.type == "image/png" ||
          value.type == "image/jpg" ||
          value.type == "image/jpeg" ||
          '"png,jpg,jpeg形式の画像ファイルを選択してください"',
      ],
    };
  },

  methods: {
    send() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.$emit("my-click", { name: this.name, file: this.file });
      }
    },
    disp() {
      if (this.file != null) {
        this.imgUrl = URL.createObjectURL(this.file);
      } else {
        this.imgUrl = "";
      }
    },
    clear() {
      this.dialog = false;
      this.name = this.topname;
      this.file = null;
      this.imgUrl = "";
    },
  },
};
</script>