<template>
  <div id="chart">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <div class="d-flex justify-center">
      <v-sheet class="ma-2 pa-2"
        ><v-btn
          color="orange"
          :dark="!uriage"
          @click="getUriageList()"
          :disabled="uriage"
          >売上</v-btn
        ></v-sheet
      >
      <v-sheet class="ma-2 pa-2"
        ><v-btn
          color="green"
          :dark="!rieki"
          @click="getRiekiList()"
          :disabled="rieki"
          >利益</v-btn
        ></v-sheet
      >
      <v-sheet class="ma-2 pa-2"
        ><v-btn
          color="primary"
          :dark="!shain"
          @click="getShain()"
          :disabled="shain"
          >社員数</v-btn
        ></v-sheet
      >
    </div>

    <apexchart
      ref="myChart"
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <EditChange
      @update-change="updateChange()"
      @update-change-cancel="updateChangeCancel()"
      :editDialog="editDialog"
      :editData.sync="editData"
    ></EditChange>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import EditChange from "@/components/business/EditChange";
export default {
  components: {
    apexchart: VueApexCharts,
    EditChange: EditChange,
  },
  props: ["change", "authority"],
  data() {
    return {
      snackbar: false,
      msg: "",
      editDialog: false,
      uriage: false,
      rieki: false,
      shain: false,
      editData: "",
      series: [
        {
          name: "実績",
          type: "column",
          data: [],
        },
        {
          name: "目標",
          type: "line",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          toolbar: false
          ,
          events: {
            click: (chart, w, e) => {
              if (this.authority.business) {
                this.editDialog = true;
                this.editData = this.change[e.dataPointIndex];
              }
            },
          },
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: "単位(億円)",
        },
        colors: ["#FFD999", "#FFA500"],
        labels: ["2021年度", "2022年度", "2023年度"],
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toLocaleString();
            },
          },
          min: 0,
          max: 30,
        },
      },
    };
  },
  created() {
    setTimeout(() => {
      this.getUriageList();
    }, 1000);
  },

  methods: {
    getUriageList() {
      this.clear();

      this.change.forEach((elem) => {
        this.series[0].data.push(elem.uriagejisseki);
        this.series[1].data.push(elem.uriagegoal);
        this.chartOptions.labels.push(elem.year + "年度");
      });
      this.chartOptions.colors.push("#FFD999", "#FFA500");
      this.chartOptions.title.text = "単位(億円)";
      this.chartOptions.yaxis.max = 30;
      this.uriage = true;
      this.rieki = false;
      this.shain = false;
      this.$refs.myChart.refresh();
    },
    getRiekiList() {
      this.clear();

      this.change.forEach((elem) => {
        this.series[0].data.push(elem.riekijisseki);
        this.series[1].data.push(elem.riekigoal);

        this.chartOptions.labels.push(elem.year + "年度");
      });
      this.chartOptions.colors.push("#B2FFB2", "#00B200");
      this.chartOptions.title.text = "単位(億円)";
      this.chartOptions.yaxis.max = 5;

      this.uriage = false;
      this.rieki = true;
      this.shain = false;
      this.$refs.myChart.refresh();
    },

    getShain() {
      this.clear();

      this.change.forEach((elem) => {
        this.series[0].data.push(elem.shainjisseki);
        this.series[1].data.push(elem.shaingoal);
        this.chartOptions.labels.push(elem.year + "年度");
      });
      this.chartOptions.colors.push("#A3CFFE", "#0073EF");
      this.chartOptions.title.text = "単位(人)";
      this.chartOptions.yaxis.max = 200;

      this.uriage = false;
      this.rieki = false;
      this.shain = true;
      this.$refs.myChart.refresh();
    },
    updateChange() {
      this.editDialog = false;
      this.snackbar = true;
      this.msg = "更新しました";
      this.getUriageList();
    },
    updateChangeCancel() {
      this.editDialog = false;
      this.getUriageList();
    },
    clear() {
      this.series[0].data.splice(0);
      this.series[1].data.splice(0);
      this.chartOptions.labels.splice(0);
      this.chartOptions.colors.splice(0);
      this.chartOptions.title.text = "";
      this.chartOptions.yaxis.max = 0;
    },
  },
};
</script>
<style>
.fixed_btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10000;
}
</style>