<template>
  <v-dialog v-model="editDialog" persistent max-width="400">
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2"
          >{{ editData.year }}年度</v-sheet
        >
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="売上目標"
                :rules="rules"
                hint="単位(億円)"
                v-model="updateData.uriagegoal"
                suffix="億円"
              ></v-text-field> </v-col
            ><v-col cols="6">
              <v-text-field
                label="売上実績"
                :rules="rules"
                hint="単位(億円)"
                v-model="updateData.uriagejisseki"
                suffix="億円"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="利益目標"
                :rules="rules"
                hint="単位(億円)"
                v-model="updateData.riekigoal"
                suffix="億円"
              ></v-text-field> </v-col
            ><v-col cols="6">
              <v-text-field
                label="利益実績"
                :rules="rules"
                hint="単位(億円)"
                v-model="updateData.riekijisseki"
                suffix="億円"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="社員数目標"
                :rules="rules"
                hint="単位(人)"
                v-model="updateData.shaingoal"
                suffix="人"
              ></v-text-field> </v-col
            ><v-col cols="6">
              <v-text-field
                label="社員数実績"
                :rules="rules"
                hint="単位(人)"
                v-model="updateData.shainjisseki"
                suffix="人"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-alert color="red lighten-2" dark v-show="msg">
            {{ msg }}
          </v-alert>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["editDialog", "editData"],

  data() {
    return {
      msg: "",
      valid: false,
      rules: [
        (v) => !!v || "入力してください",
        (v) => /^[0-9.]+$/.test(v) || "数字を入力してください",
        (v) => !/^\..*/.test(v) || "入力値が不正です",
        (v) => (String(v).match(/\./g) || []).length <= 1 || "入力値が不正です",
      ],
      updateData: {
        year: "",
        uriagegoal: "",
        riekigoal: "",
        uriagejisseki: "",
        riekijisseki: "",
        shainjisseki: "",
        shaingoal: "",
      },
    };
  },
  methods: {
    send() {
      if (this.$refs.form.validate()) {
        axios
          .post(process.env.VUE_APP_API_URL + "/update-business-change", {
            change: this.updateData,
          })
          .then((response) => {
            if (response.data.result == "OK") {
              this.editData.uriagegoal = this.updateData.uriagegoal;
              this.editData.riekigoal = this.updateData.riekigoal;
              this.editData.uriagejisseki = this.updateData.uriagejisseki;
              this.editData.riekijisseki = this.updateData.riekijisseki;
              this.editData.shainjisseki = this.updateData.shainjisseki;
              this.editData.shaingoal = this.updateData.shaingoal;
              this.$emit("update-change");
            }
          });
      }
    },
    cancel() {
      this.$emit("update-change-cancel");
    },
  },
  watch: {
    editDialog() {
      this.updateData = Object.assign({}, this.editData);
    },
  },
};
</script>
<style>
.right-input input {
  text-align: right;
}
</style>