<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="editdata"
        v-bind="attrs"
        v-on="on"
        @click="target = editdata"
      >
        mdi-pencil
      </v-icon>
      <v-icon v-else v-bind="attrs" v-on="on"> mdi-plus </v-icon>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet v-if="year" class="text-h6 left-color pa-2"
          >{{ year }}年度</v-sheet
        >
        <v-card-text>
          <v-row>
            <v-col>
              <v-textarea
                height="400"
                outlined
                v-model="target"
                :rules="rules"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["editdata", "year"],
  data() {
    return {
      dialog: false,
      valid: false,
      rules: [(v) => !!v || "入力してください"],
      target: "",
    };
  },

  methods: {
    send() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.$emit("my-click", this.target);
        this.$refs.form.reset()
      }
    },
    cancel(){
      this.dialog = false;
      this.$refs.form.reset()
    }
  },
};
</script>