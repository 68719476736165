<template>
  <v-container fluid class="down-top-padding">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-card elevation="1">
          <v-sheet class="text-h6 pa-2 left-color"
            >経営目標
            <EditTextarea
              v-if="authority.business"
              @my-click="updateGoal($event)"
              :editdata="goal"
            ></EditTextarea>
          </v-sheet>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" align="center">
                <v-sheet
                  ><v-img width="200px" height="200px" :src="photo"></v-img
                ></v-sheet>
                <v-sheet
                  >{{ topname
                  }}<EditTopname
                    v-if="authority.business"
                    :topname="topname"
                    @my-click="updateTopname($event)"
                  ></EditTopname
                ></v-sheet>
              </v-col>
              <v-col cols="12" md="9" class="detail"
                ><v-card-text :class="flash">{{ goal }}</v-card-text></v-col
              >
            </v-row>
            <v-row justify="end" class="ma-3">
              {{ goalupdate | moment }}
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card elevation="1">
          <v-sheet class="text-h6 pa-2 left-color"
            >売上・社員数推移

            <v-icon @click="addChange()" v-if="authority.business">
              mdi-plus
            </v-icon>

            <EditChange
              @update-change="updateChange()"
              @update-change-cancel="updateChangeCancel()"
              :editDialog="editDialog"
              :editData.sync="editData"
            ></EditChange>
          </v-sheet>
          <ChangeChart
            ref="myChart"
            :change="change"
            :authority="authority"
          ></ChangeChart> </v-card
      ></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet class="text-h6 pa-2 left-color" elevation="1"
          >振り返り
          <EditTextarea
            v-if="authority.business"
            :year="maxLookbackYear()"
            @my-click="updateLookback($event, maxLookbackYear())"
          ></EditTextarea>
        </v-sheet>
        <v-col>
          <v-expansion-panels v-model="panel" :key="refkey">
            <v-expansion-panel v-for="item in lookback" :key="item.year">
              <v-expansion-panel-header
                class="text-subtitle-1"
                color="blue lighten-4"
                ><div>
                  {{ item.year }}年度
                  <EditTextarea
                    v-if="authority.business"
                    @my-click="updateLookback($event, item.year)"
                    :editdata="item.text"
                    :year="item.year"
                  ></EditTextarea>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-card>
                  <v-card-text class="pa-0 detail">{{ item.text }}</v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels></v-col
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import ChangeChart from "@/components/business/ChangeChart";
import EditTextarea from "@/components/business/EditTextarea";
import EditTopname from "@/components/business/EditTopname";
import EditChange from "@/components/business/EditChange";

export default {
  name: "BusinessPage",
  filters: {
    moment(value) {
      return moment(value).format("更新日：YYYY年MM月DD日");
    },
  },
  data: () => ({
    editDialog: false,
    editData: {},
    authority: [],
    snackbar: false,
    msg: "",
    panel: 0,
    topname: "",
    photo: "",
    goal: "",
    goalupdate: "",
    change: [],
    lookback: [],
    flash: "",
    refkey: 0,
  }),
  components: { ChangeChart, EditTextarea, EditTopname, EditChange },
  methods: {
    changeFunc() {
      this.snackbar = true;
      setTimeout(() => {
        this.changed = "";
      }, "2000");
    },
    setFlash() {
      this.flash = "animate__animated animate__flash";
      setTimeout(() => {
        this.flash = "";
      }, "2000");
    },
    getBusinessInfo() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-business")
        .then((response) => {
          this.topname = response.data.topname;
          this.photo = response.data.photo;
          this.goal = response.data.goal;
          this.goalupdate = response.data.goalupdate;
          this.change.splice(0);
          response.data.change = _.sortBy(response.data.change, "year");
          if (response.data.change.length > 10) {
            response.data.change = response.data.change.slice(
              response.data.change.length - 10,
              response.data.change.length
            );
          }
          response.data.change.forEach((elem) => {
            this.change.push(elem);
          });
          this.lookback.splice(0);
          response.data.lookback = _.sortBy(
            response.data.lookback,
            "year"
          ).reverse();
          response.data.lookback.forEach((elem) => {
            this.lookback.push(elem);
          });
        });
    },
    getUserAuthority() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-authority")
        .then((response) => {
          this.authority = response.data.authority;
        });
    },
    updateGoal(e) {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-business-goal", {
          goal: e,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.snackbar = true;
            this.msg = "更新しました";
            this.setFlash();
            this.getBusinessInfo();
          }
        });
    },
    updateTopname(e) {
      const senddata = new FormData();
      senddata.append("files", e.file);
      senddata.append("name", e.name);

      axios
        .post(
          process.env.VUE_APP_API_URL + "/update-business-topname",
          senddata,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        )
        .then((response) => {
          if (response.data.result == "OK") {
            window.location.reload();
          }
        });
    },
    updateLookback(e, year) {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-business-lookback", {
          year: year,
          text: e,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.snackbar = true;
            this.msg = "更新しました";
            this.getBusinessInfo();
            setTimeout(() => {
              this.refkey = this.refkey + 1;
            }, 1000);
          }
        });
    },
    addChange() {
      this.editData.year = this.maxChangeYear();
      this.editDialog = true;
    },
    maxChangeYear() {
      return (
        Math.max.apply(
          null,
          this.change.map(function (o) {
            return o.year;
          })
        ) + 1
      );
    },
    maxLookbackYear() {
      return (
        Math.max.apply(
          null,
          this.lookback.map(function (o) {
            return o.year;
          })
        ) + 1
      );
    },
    updateChange() {
      this.editDialog = false;
      this.snackbar = true;
      this.msg = "追加しました";
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    updateChangeCancel() {
      this.editDialog = false;
    },
  },

  created() {
    this.getUserAuthority();
    this.getBusinessInfo();
  },
};
</script>
<style>
.detail {
  white-space: pre-line;
}
table tr:nth-child(odd) td {
  background: #9696961a;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>